import difference from "lodash/difference";
import union from "lodash/union";
import { ROOT_STORE_STORAGE_INDEX } from "../common/env";
import { getNextForm } from "../functions/formutil";
import citizenship from "../questionnaire/citizenship";
import family from "../questionnaire/family";
import patrimonio from "../questionnaire/patrimonio";
import redditi from "../questionnaire/redditi";
import { getCurrentStateFromLocalStorage } from "../utils";

export const defaultQuestionnaireForms: RootStoreModule.Form[] = [
  citizenship,
  family,
  redditi,
  patrimonio,
] as unknown as RootStoreModule.DefaultQuestionnaireForms;

const DEFAULT_RESIDENCE_COUNTRY = "IT";
const DEFAULT_CITIZENSHIP_COUNTRY = [DEFAULT_RESIDENCE_COUNTRY];

const initialState = {
  formValues: { guidedMode: true, Nome: null, applicant_PosizioneResidenza: null },
  stepHistory: [],
  changeSection: { section: "welcome", direction: null },
  currentForm: "welcome",
  activeStep: 0,
  activeStepName: defaultQuestionnaireForms[0].sections[0].forms[0].stepname,
  validationField: {},
  forms: defaultQuestionnaireForms,
  redirectOnEndSection: "",
  redirectOnFinishSection: "",
  keySaveState: ROOT_STORE_STORAGE_INDEX,
  questionnaireHasBeenDownloaded: false,
};

export default function updates(state = getCurrentStateFromLocalStorage(initialState), action: any) {
  switch (action.type) {
    case "SET_FORM_NAME":
      return { ...state, formValues: { ...state.formValues, Nome: action.payload } };
    case "SET_DEFAULT_FIRST_STEP_QUESTIONNAIRE_DATA":
      return {
        ...state,
        formValues: {
          ...state.formValues,
          CodiceFiscale: action.payload.taxCode,
          DataDiNascita: action.payload.birthDate,
          PrevDataDiNascita: action.payload.prevDataDiNascita,
          AttivitaPrincipale: action.payload.mainActivity,
          LuogoDiNascita: action.payload.birthPlace,
          SessoAnagrafico: action.payload.registrySex,
        },
      };
    case "CHANGE_SECTION": {
      const form = getNextForm(state.forms, action.payload.section || state.currentForm, action.payload.direction);

      return {
        ...state,
        currentForm: form.name,
        activeStep: 0,
        stepHistory: [],
        activeStepName: action.payload.activeStepName || form.sections[0].forms[0].stepname,
      };
    }
    case "CHANGE_STEP": {
      return {
        ...state,
        activeStep: action.payload,
      };
    }
    case "CHANGE_STEP_NAME": {
      return {
        ...state,
        activeStepName: action.payload,
      };
    }
    case "UPDATE_FIELD": {
      const prevFormValues = state.formValues;
      const forms = state.forms;

      const fieldName = action.payload.name;
      const fieldValue = action.payload.value;

      let newFormValues = prevFormValues;

      if (prevFormValues[fieldName] !== fieldValue) {
        const prevFormValuesKeys = Object.keys(prevFormValues || {});

        const allFormFields: Array<any> = [];
        const notDependentFields: Array<any> = [];

        forms.forEach((form: any) => {
          const sections = form.sections;
          const steps = sections[0].forms;

          steps.forEach((step: any) => {
            step.fields.forEach((field: any) => {
              allFormFields.push(field.name);
              if (!field.dependencies || !field.dependencies.includes(fieldName)) {
                notDependentFields.push(field.name);
              }
            });
          });
        });

        const diffPrevFields = difference(prevFormValuesKeys, allFormFields);
        const mergeBetweenNotToExcludeFieldsAndNotDepdendent = union(diffPrevFields, notDependentFields);

        newFormValues = {};

        mergeBetweenNotToExcludeFieldsAndNotDepdendent.forEach((x) => {
          if (prevFormValues[x] !== undefined) {
            newFormValues[x] = prevFormValues[x];
          }
        });

        //! repopulates with default
        forms.forEach((form: any) => {
          const sections = form.sections;
          const steps = sections[0].forms;

          steps.forEach((step: any) => {
            step.fields.forEach((field: any) => {
              if (
                (newFormValues[field.name] === undefined || newFormValues[field.name] === null) &&
                field.default !== undefined
              ) {
                newFormValues[field.name] = field.default;
              }
            });
          });
        });
      }

      return {
        ...state,
        formValues: { ...newFormValues, [fieldName]: fieldValue },
      };
    }
    case "RESTORE_SECTION": {
      return {
        ...state,
        changeSection: {
          section: state.changeSection.section,
          direction: null,
        },
      };
    }

    case "VALIDATION_FIELD_ERROR": {
      return {
        ...state,
        validationField: action.payload,
      };
    }

    case "CHANGE_CURRENT_FORM": {
      return {
        ...state,
        currentForm: action.payload.form,
      };
    }

    case "UPDATE_QUESTIONNAIRE": {
      let tmpQuestionnaireHasBeenDownloaded = state.questionnaireHasBeenDownloaded;

      if (action.payload.notify === true) {
        tmpQuestionnaireHasBeenDownloaded = true;
      }

      const questionnaire = {
        ...state,
        ...action.payload.questionnaire,
        questionnaireHasBeenDownloaded: tmpQuestionnaireHasBeenDownloaded,
      };

      //! QUICK AND DIRTY 'paesi_cittadinanza' field with default value
      const citizenshipCountryFormValue =
        (questionnaire.formValues.paesi_cittadinanza || action.payload.questionnaire.paesi_cittadinanza) ??
        DEFAULT_CITIZENSHIP_COUNTRY;
      const isCitizenshipCountryFormValueAnArray = Array.isArray(citizenshipCountryFormValue);

      let citizenshipCountryFormValueComputed: string[] = [];
      if (isCitizenshipCountryFormValueAnArray) {
        citizenshipCountryFormValueComputed = citizenshipCountryFormValueComputed.concat(citizenshipCountryFormValue);
      } else {
        citizenshipCountryFormValueComputed.push(citizenshipCountryFormValue);
      }
      // END QUICK AND DIRTY SOLUTION

      //! QUICK AND DIRTY 'paese_residenza' field with default value
      const citizenshipResidenceFormValue =
        (questionnaire.formValues.paese_residenza || action.payload.questionnaire.paese_residenza) ??
        DEFAULT_RESIDENCE_COUNTRY;
      // END QUICK AND DIRTY SOLUTION

      const newFormValues = {
        ...questionnaire.formValues,
        applicant_PosizioneResidenza:
          questionnaire.formValues.applicant_PosizioneResidenza ||
          action.payload.questionnaire.applicant_PosizioneResidenza,
        paesi_cittadinanza: citizenshipCountryFormValueComputed,
        paese_residenza: citizenshipResidenceFormValue,
      };

      questionnaire.formValues = newFormValues;

      return questionnaire;
    }

    case "UPDATE_ENTIRE_FORMS": {
      return { ...state, forms: action.payload };
    }

    case "RESET": {
      return { ...initialState };
    }

    case "SHOW_GUIDED_MODE": {
      return { ...state, formValues: { ...state.formValues, guidedMode: action.payload } };
    }

    case "SET_TAX_CODE_DATA": {
      return { ...state, formValues: { ...state.formValues, taxCodeData: action.payload } };
    }

    default:
      return state;
  }
}
