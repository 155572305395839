import { AxiosError } from "axios";
import CodiceFiscale from "codice-fiscale-js";
//@ts-ignore
import IBAN from "iban";
import flatten from "lodash/flatten";
import moment from "moment";
import { stringify } from "qs";
import { ROOT_STORE_STORAGE_INDEX } from "../common/env";
import { checkForStep, getValuesFromName, switchForStep } from "../functions/formutil";
import { IForm } from "../interfaces/IForm";
export * as session from "./session";

export const calculatePercentageForm = (form: IForm, activeStepName: string, formValues?: any, exact?: boolean) => {
  const steps = flatten(form?.sections?.map((s) => s.forms.map((form) => form.stepname)));
  const stepsWithNextStep = flatten(form?.sections?.map((s) => s.forms.map((form) => form)));

  let currentStepIndex = -1;
  if (activeStepName) {
    currentStepIndex = steps.findIndex((x) => x === activeStepName);
  }

  if (!formValues && currentStepIndex === -1) return 0;

  let totalSteps = steps?.length || 1;
  let calcPercentage = (currentStepIndex / totalSteps) * 100;

  if (calcPercentage < 0) calcPercentage = 0;

  if (formValues) {
    let activeStepIndex = -1;
    // console.log(form?.name + "_test");
    let nextStep = stepsWithNextStep?.[0]?.stepname;
    let prevNextNextStep = "";
    let isValid = true;

    while (isValid && !["end", "finish"].includes(nextStep) && prevNextNextStep != nextStep) {
      // console.log(form?.name, "voloe");
      activeStepIndex = nextStep ? stepsWithNextStep.findIndex((x) => x.stepname === nextStep) : activeStepIndex;

      const currentField = stepsWithNextStep[activeStepIndex]?.fields?.[0];
      const currentStepFieldName = currentField?.name;
      // const currentValue = formValues?.[currentStepFieldName];

      isValid = !currentStepFieldName || (currentStepFieldName && !validate(currentField, formValues));

      if (isValid) {
        prevNextNextStep = nextStep;
        nextStep = calculateNextStep(activeStepIndex, formValues?.[currentStepFieldName], {
          forms: stepsWithNextStep,
          formValues,
        });
      }
    }

    // stepsWithNextStep.forEach((currentStep, k) => {
    //   const currentValue = formValues?.[stepsWithNextStep[k]?.fields?.[0]?.name];
    //   const isValid = Boolean(currentValue);

    //   if (currentValue !== undefined && isValid) activeStepIndex = k;
    // });

    calcPercentage = (activeStepIndex / totalSteps) * 100;
    if (calcPercentage < 0) calcPercentage = 0;

    // const activeStepIndex = stepsWithNextStep.findIndex((x) => formValues[x.fields?.[0].name || ""] !== undefined);
    const activeStep = stepsWithNextStep[activeStepIndex];

    const currentField = activeStep?.fields?.[0];
    const currentStepFieldName = currentField?.name;
    // console.log(form?.name + "_step", activeStep, currentStepFieldName);

    // const currentValue = formValues?.[currentStepFieldName];

    isValid = !currentStepFieldName || (currentStepFieldName && !validate(currentField, formValues));

    let calculatedNextStep: string = "";
    calculatedNextStep = calculateNextStep(activeStepIndex, formValues?.[currentStepFieldName], {
      forms: stepsWithNextStep,
      formValues,
    });

    //console.log("CALCULATE FORM", form?.name, activeStepIndex, calculatedNextStep, calcPercentage);

    if ((calculatedNextStep === "finish" || calculatedNextStep === "end") && isValid) {
      if (calculatedNextStep === "finish" && !exact) return 999; //! debug purpuse

      return 100;
    }
  }

  return Math.round(calcPercentage);
};

export const parseDefaultValue = (defaultValue: string, formValues: any[]) => {
  return defaultValue;
};

export function getCurrentStateFromLocalStorage(initialState?: any) {
  const locationToCheck = ROOT_STORE_STORAGE_INDEX;

  const persistedValue = localStorage.getItem(locationToCheck);

  if (!persistedValue) return initialState;

  const parsedState = JSON.parse(persistedValue);

  return {
    ...parsedState,
    forms: initialState?.forms || parsedState?.forms,
    header: initialState?.header,
  };
}

export const apiErrorParser = (axiosError: AxiosError) => {
  const resp = axiosError.response?.data;
  let error = "genericError";

  if (resp?.errors) {
    error = resp?.errors?.[0]?.param || "genericError";
  } else {
    error = resp?.message || "genericError";
  }

  return error;
};

export function getFullName(person?: { name?: string; surname?: string }) {
  return (person?.name || "") + " " + (person?.surname || "");
}

// export const apiErrorParser =  async <T extends Object> (api: Promise<AxiosResponse<T>>) => {
//   return new Promise((resolve) => {
//    api
//       .then((res) => {
//         if (res.status >= 300 || res.status < 200) {
//           res.json().then((bodyErrors) => {
//             if (bodyErrors?.errors) {
//               resolve({ error: bodyErrors?.errors?.[0]?.param || "genericError" });
//             } else {
//               resolve({ error: bodyErrors?.message || "genericError" });
//             }
//           });
//         } else {
//           resolve(res.json());
//         }
//       })
//       .catch(console.log);
//   });
// };

export function backendMediaUrlParser(
  fileUrl?: string,
  options?: { download?: boolean; filename?: string; width?: number; height?: number }
) {
  if (!fileUrl) return undefined;
  if (fileUrl.includes("blob:")) return fileUrl;

  const { download, filename, width, height } = options || {};

  let url = process.env.REACT_APP_MAIN_URL + "/sapi/media/" + fileUrl;

  if (width || height) {
    url = `${url}?${width ? "w=" + width : ""}${height ? (width ? "&" : "") + ("h=" + height) : ""}`;
  }

  if (download || filename) {
    let tmpParams = stringify({ download, filename });
    url += `?${tmpParams}`;
  }

  return url;
}

export const DocumentCategory = [
  { id: 0, value: "Personali" },
  { id: 1, value: "Redditi e lavoro" },
  { id: 2, value: "Proprietà e affitti" },
  { id: 3, value: "Conti e titoli " },
  { id: 4, value: "Veicoli" },
  { id: 5, value: "Certificati e ricevute" },
  { id: 6, value: "ISEE" },
  { id: 8, value: "Pratica" },
  { id: 9, value: "Spese sostenute" },
  { id: 10, value: "Previdenza" },
  { id: 11, value: "Fisco" },
  { id: 7, value: "Altro" },
];

export const ServiceCategory = [
  { id: 0, value: "Famiglia" },
  { id: 1, value: "Giovani" },
  { id: 2, value: "Anziani" },
  { id: 3, value: "Studenti " },
  { id: 4, value: "Casa" },
  { id: 5, value: "Veicoli e trasporti" },
  { id: 6, value: "Imprese" },
  { id: 7, value: "Lavoratori e disoccupati" },
  { id: 8, value: "Pensionati" },
  { id: 9, value: "Salute" },
  { id: 10, value: "Tempo libero" },
  { id: 11, value: "Servizi Accessori" },
];

export const ToSignDocumentCategory = [
  { id: 0, value: "Mandato" },
  { id: 1, value: "Trattamento Dati" },
  { id: 2, value: "Dichiarazione sostitutiva unica (DSU)" },
  { id: 4, value: "Modello dimissioni volontarie" },
  { id: 5, value: "Autodichiarazione di residenza" },
  { id: 6, value: "Autodichiarazione del reddito" },
  { id: 3, value: "Altro" },
];

export const SelectMainActivityOptions = [
  { id: 0, value: "Seleziona una attività" },
  { id: 1, value: "Lavoro dipendente a tempo indeterminato" },
  { id: 2, value: "Lavoro dipendente a tempo determinato o con contratto di apprendistato" },
  { id: 3, value: "Lavoro con contratto di somministrazione (“interinale”)" },
  {
    id: 4,
    value:
      "Lavoro o disoccupazione con sostegno al reddito (cassa integrazione, NASpI, lavori socialmente utili, ecc.) ",
  },
  { id: 5, value: "Lavoro parasubordinato (coll. a progetto o Co.Co.Co)" },
  { id: 6, value: "Lavoro accessorio (con voucher, occasionale, tirocinio/stage, ecc.)" },
  { id: 7, value: "Lavoro autonomo" },
  { id: 8, value: "Senza occupazione" },
  { id: 9, value: "Pensione" },
  { id: 10, value: "Cura della casa (casalinga/o)" },
  { id: 11, value: "Studio (studente universitario, liceale, ecc.)" },
  { id: 12, value: "Altro" },
];

export const SelectParentOptions = [
  { id: 1, value: "Coniuge" },
  { id: 2, value: "Unito civilmente" },
  { id: 3, value: "Figlio/a" },
  { id: 4, value: "Minore in affido preadottivo" },
  { id: 5, value: "Genitore" },
  { id: 6, value: "Sorella/Fratello" },
  { id: 7, value: "Nonno/a" },
  { id: 8, value: "Nipote" },
  { id: 9, value: "Zio/a" },
  { id: 10, value: "Cugino/a" },
  { id: 11, value: "Altro (convivente di fatto, ecc.)" },
];

export const necessaryDataPurchaseOptions = [
  { id: "personal_data", value: "Dati personali" },
  { id: "family_questionnaire", value: "Nucleo familiare" },
  { id: "family_data", value: "Dati dei familiari" },
  // { id: "citizenship_questionnaire", value: "Dati personali" },
  { id: "income_questionnaire", value: "Redditi" },
  { id: "full_questionnaire", value: "Patrimonio" },
  // { id: "heritage_questionnaire", value: "Patrimonio" },
];

/**
 * Trims the string to {max} value and adds to the end "..."
 *
 * @param {string} value
 * @param {number} max
 */
export function trimStringToMax(value: string | undefined, max: number) {
  if (!value) return "";
  if (value.length < max) {
    return value;
  }

  return value.substr(0, max) + "...";
}

const validate = (field: any, v: any) => {
  let value = v?.[field?.name];
  if (field?.type === "yesno") {
    if (field?.optional) return false;
    return value === undefined;
  }

  switch (field?.validation) {
    case "multiple_has":
      const parsedValue = ((typeof value === "string" ? JSON.parse(value) : value) || []).filter((x: any) => x);

      const hasError = parsedValue?.some((x: any) => {
        const keys = Object.keys(x);
        return keys.some((key) => !x[key]);
      });
      if (hasError || parsedValue?.length === 0) return true;

      break;
    case "tax_code":
      if (!CodiceFiscale.check(value)) return true;
      break;
    case "iban":
      if (!IBAN.isValid(value)) return true;
      break;
    case "has":
      if (field.type === "multipleselection") {
        if (
          ((value || []).length <= 0 || ((value || []).length === 1 && (value || []).includes(""))) &&
          !field.optional
        )
          return true;
      }
      if ((!value || (value && value.length < 1)) && !field.optional) return true;
      break;
    case "eval":
      const moment = require("moment");
      moment.locale("it");

      //  if the field is optional but the user type a value, then does the check
      //  if the field is optional but the user doesn't type anything, doesn't the check
      //  value.length > 0 return false and doesn't check the value

      try {
        //! here was isNaN
        if (field.type === "number") {
          if (!eval(field.expression) && (!field.optional || (value?.toString().length > 0 && !isNaN(value)))) {
            return true;
          }
        } else if (!eval(field.expression) && (!field.optional || value?.toString().length > 0)) {
          return true;
        }
      } catch (err) {
        return true;
      }
      break;
    case "number":
      if (!value || (value && isNaN(value))) return true;
      break;
    case "selected":
      if (!value || (value && value.length < 1)) return true;
      break;

    default:
      break;
  }

  return false;
};

export const calculateNextStep = (activeStep: number, response: boolean, props: { forms: any[]; formValues: any }) => {
  const tmpFunc = props?.forms?.[activeStep]?.nextstep;
  const func = typeof tmpFunc === "string" ? tmpFunc : (tmpFunc || []).join("");

  const ynStep = func?.split(":");
  if (ynStep.length > 1) {
    if (ynStep[0] === "check") {
      // console.log("Check", ynStep);
      return checkForStep(ynStep[1], props.formValues[ynStep[2]], ynStep[3], ynStep[4], ynStep[5]);
    }

    if (ynStep[0] === "select") {
      const next = ynStep[1 + parseInt(getValuesFromName(props.formValues, ynStep[1]))];
      return next;
    }

    if (ynStep[0] === "eval") {
      const moment = require("moment");
      moment.locale("it");

      const v = props.formValues;
      // console.log("Eval", ynStep[1], eval(ynStep[1]), v)

      if (eval(ynStep[1])) return ynStep[2];
      return ynStep[3];
    }

    if (ynStep[0] === "switch") {
      const v = props.formValues;
      // console.log("Switch", ynStep);
      ynStep.shift();
      return switchForStep(v, ynStep.join(":"), response);
    }

    if (response) {
      const next = ynStep[0];
      return next;
    } else {
      const next = ynStep[1];
      return next;
    }
  }
  const next = func;

  return next;
};

export const elaborateFamilyOptions = (familyData: any[]) => {
  return (familyData || [])?.map((x) => ({ [x?.id || ""]: getFullName(x) }));
};

export function rawToArray(value?: any | any[]) {
  if (!value) return [];
  if (typeof value !== "object") return [value];
  else return value;
}

export function getBirthDateFromTaxCode(taxCode: string) {
  if (!taxCode) return undefined;
  if (!CodiceFiscale.check(taxCode)) return undefined;

  const computed = CodiceFiscale.computeInverse(taxCode);
  const momentComputed = moment()
    .year(computed.year)
    .month(computed.month - 1)
    .date(computed.day);

  return momentComputed;
}

export function findLastFieldToBeCompleted(forms: any[], formValues: any) {
  let lastFieldToBeCompleted = "";

  if (forms.length > 0) {
    const lastForm: any = forms[forms.length - 1];
    const innerForms = lastForm.sections[0].forms;

    let lastInnerForm = innerForms[innerForms.length - 1];

    lastFieldToBeCompleted = lastInnerForm?.fields?.[0].name;
    if (formValues[lastFieldToBeCompleted] === undefined) {
      let lastInnerForm = innerForms?.[innerForms.length - 2];

      lastFieldToBeCompleted = lastInnerForm?.fields[0]?.name;

      if (formValues[lastFieldToBeCompleted] === undefined) {
        let lastInnerForm = innerForms?.[innerForms.length - 3];

        lastFieldToBeCompleted = lastInnerForm?.fields[0]?.name;
      }
    }
  }
  return lastFieldToBeCompleted;
}

export function replaceMemberField(s: string, member: any) {
  s = s.replace(/%(.*?)%/gi, function (a, b) {
    const v = member[b];
    return v;
  });

  return s;
}
