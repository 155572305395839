import { Client, EvaluationContext, OpenFeature } from "@openfeature/react-sdk";
import { APP_VERSION, FEATURE_FLAG_USER_ID } from "../../common/env";
import { IFeatureFlagProviderFactory } from "../../interfaces/feature-flag-provider-factory.interface";
import { ConfigCatProviderFactory } from "../ConfigCatProviderFactory/config-cat-provider-factory";
import { IFeatureFlagService } from "./feature-flag.interface";

export class FeatureFlagService implements IFeatureFlagService {
  private _USER_ID = FEATURE_FLAG_USER_ID;
  private _APP_VERSION = APP_VERSION;
  private _openFeatureClient: Client | undefined = undefined;

  constructor(private _featureFlagProviderFactory: IFeatureFlagProviderFactory = new ConfigCatProviderFactory()) {}

  async initialize(): Promise<IFeatureFlagService> {
    const devCycleProvider = await this._featureFlagProviderFactory.create();

    const context = this._getContext();
    await OpenFeature.setContext(context);
    await OpenFeature.setProviderAndWait(devCycleProvider);

    this._openFeatureClient = OpenFeature.getClient();
    return this;
  }

  public getClient(): Client | undefined {
    return this._openFeatureClient;
  }

  private _getContext(): EvaluationContext {
    const appVersion = this._APP_VERSION;
    const userId = this._USER_ID;
    return { user_id: userId, appVersion };
  }
}
