import { ThemeProvider } from "@bonusx-global/bx-design-system";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { OpenFeatureProvider } from "@openfeature/react-sdk";
import * as Sentry from "@sentry/react";
import { Provider as JotaiProvider } from "jotai";
import { default as momentModule } from "moment";
import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "reflect-metadata";
import "./assets/css/main.css";
import { APP_VERSION } from "./common/env";
import { sentryConfig } from "./common/sentryConfig";
import { InitializeApp } from "./components/InitializeApp";
import SuspenceWrapper from "./components/SuspenseWrapper";
import { WindowBeforeUnload } from "./components/WindowBeforeUnload";
import { ErrorHttpProvider } from "./contexts/ErrorHttpContext/providers/ErrorHttpProvider";
import "./index.css";
import "./polyfill/promise";
import { FeatureFlagService } from "./services/FeatureFlag/feature-flag.service";
import store from "./store";
import citizenTheme from "./styles/citizenTheme";
import domReadyPromise from "./utils/domReadyPromise";

window.moment = momentModule;
window.moment.locale("it");
window.year = window.moment().year();

Sentry.init(sentryConfig);
Sentry.setTag("app_version", APP_VERSION);

const App = lazy(() => import("./App"));

const featureFlag = new FeatureFlagService();
featureFlag
  .initialize()
  .then(() => {
    console.log("Feature Flag initialized");
  })
  .catch((error) => {
    console.error("Feature Flag error:", error);
  });

domReadyPromise()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <OpenFeatureProvider>
          <Provider store={store}>
            <JotaiProvider>
              <SuspenceWrapper>
                <ErrorHttpProvider>
                  <InitializeApp />
                  <WindowBeforeUnload />
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={citizenTheme}>
                      <App />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </ErrorHttpProvider>
              </SuspenceWrapper>
            </JotaiProvider>
          </Provider>
        </OpenFeatureProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch((error) => {
    console.error("APP  ERROR:", error);
    window.location.href = "/unexpected-error.html";
  });
