import { ConfigCatProvider } from "@openfeature/config-cat-provider";
import { Provider } from "@openfeature/web-sdk";
import { LogLevel, PollingMode, createConsoleLogger } from "configcat-js";
import { FEATURE_FLAG_API_KEY } from "../../common/env";
import { IFeatureFlagProviderFactory } from "../../interfaces/feature-flag-provider-factory.interface";

export class ConfigCatProviderFactory implements IFeatureFlagProviderFactory {
  private _FEATURE_FLAG_API_KEY = FEATURE_FLAG_API_KEY;

  create(): Promise<Provider> {
    const apiKey = this._getApiKey();
    const options = this._getProviderConfig();
    const provider = ConfigCatProvider.create(apiKey, PollingMode.LazyLoad, options);

    return Promise.resolve(provider as unknown as Provider);
  }

  private _getProviderConfig() {
    const logger = createConsoleLogger(LogLevel.Debug);

    return {
      logger,
      setupHooks: (hooks: any) => hooks.on("clientReady", () => console.log("ConfigCat Client is ready!")),
    };
  }

  private _getApiKey() {
    return this._FEATURE_FLAG_API_KEY;
  }
}
